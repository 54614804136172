import './App.css';
import { motion } from "framer-motion";
import dex from './dex.png';
import tg from './tg.png';
import xcom from './xcom.png';
import wt from './wt.png';
import logotext from './logotext.gif';
import sticker from './sticke.PNG';
import { useState, useEffect } from 'react';
import wlt from './wallet.gif';
import lgt from './lgt.PNG';
import { useInView } from 'react-intersection-observer';
import footlogo from './footlogo.gif';
import early from './early.PNG';
import mad from './yodatalk.PNG';
import r1 from './logo.PNG';
import r2 from './r2.PNG';
import r3 from './r3.PNG';
import swing from './ani.PNG';

import red from './red.PNG';
import elec from './electric.PNG';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import footimg from './foot.png';

import soljak from './SOLjak.PNG';
import memes from './memes';

//import memes from './memes';
function Home() {


  const api_key = 'AIzaSyB4F1HkzTb4StlIFJfSwvd5qEdBInK4Wgs';

  const [socials, setSocials] = useState([]);


  useEffect(() => {

    const url = `https://sheets.googleapis.com/v4/spreadsheets/1LRwgs_JT_J9M1gCVAqoGpcC9euN2vL5i7wRg6FDpJSo/values/Sheet1!B2:B?key=${api_key}`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setSocials(data.values.map((x) => x[0] || ''));
      })
      .catch(error => {
        setSocials(['https://t.me/', 'https://x.com/', 'https://dexscreener.com/solana/', 'https://jup.ag/', 'CA COMING SOON']);
      });
  }, [])

  const copyText = () => {
    var text = document.getElementById("ca").innerText;

    // Create a temporary textarea element
    var textarea = document.createElement("textarea");

    // Set the text content to the paragraph text
    textarea.value = text.replace("COPY CONTRACT ADDRESS", "");

    console.log(textarea.value);

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text inside the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(textarea);

    // Alert the user that the text has been copied
    alert("WABBIT CA copied.");
  }

  const marqueeVariants = {
    animate: {
      x: [0, -1250],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: 30,
          ease: "linear",
        },
      },
    },
  };


  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const { ref: ref2, inView: inView2 } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const { ref: ref3, inView: inView3 } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });


  const { ref: ref4, inView: inView4 } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const { ref: ref6, inView: inView6 } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });


  const { ref: ref5, inView: inView5 } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const { ref: ref8, inView: inView8 } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });













  return (
    <div className="home texture">

      <LazyLoadImage src={footlogo} alt="hello" className='pinkbag' onClick={() => {
        window.open(socials[0], '_blank');
      }} />
      <div ref={ref} className={`section ${inView ? 'visible' : 'hidden'} land hero`}>

        {/* <LazyLoadImage src={herobg} alt='hello' className='hbg blur' /> */}

        <div className='left'>

          <div className='ltextcont2'>
            {/* <h1>THE ADVENTUROUS MASCOT</h1> */}

            {/* 
            <img src={logo} alt='hello' className='logo' /> */}



            <LazyLoadImage src={logotext} alt='hello' className='logo2 pulse' />
            {/* 
            <img src={footlogo} alt="hello" className='shakeRotate footimg footimgabs' onClick={() => {
              window.open(socials[0], '_blank');
            }} /> */}



            <div className='buttonsH'>
              <a className='logs' href={socials[0]} target='_blank' rel="noreferrer">
                <LazyLoadImage src={tg} className='dex' alt='dex' />
              </a>

              <a className='logs' href={socials[1]} target='_blank' rel="noreferrer">
                <LazyLoadImage src={xcom} className='dex' alt='dex' />

              </a>
              <a className='logs' href={socials[2]} target='_blank' rel="noreferrer">
                <LazyLoadImage src={dex} className='dex' alt='dex' />
              </a>
              <div className='btnBig'><a href={socials[3]} target="_blank" rel="noreferrer">TRADE</a></div>
            </div>
          </div>

        </div>



      </div>
      <div className='marquee-cont'>

        <motion.div
          className="track"
          variants={marqueeVariants}
          animate="animate"
        >
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
        </motion.div>
      </div>


      <div ref={ref2} className={`section ${inView2 ? 'visible' : 'hidden'} land test snow`}>


        <div className='left'>

          <div className='ltextcont2' >
            {/* <h1 className='sText'>BRING OUT YOUR<span className='sText'> SABERS</span></h1> */}



            <div className='aboutcontainer'>

              <h1 className='sText'>Wabbit the <span className='sText light'>HORNIEST </span>rabbit ever. <span className='sText light'>CUMMING </span> on Solana.</h1>
              <LazyLoadImage src={soljak} alt="hello" style={{ height: "22rem", width: "auto" }} className='shakeRotate' />

            </div>




          </div>

        </div>



      </div >
      <div className='marquee-cont'>

        <motion.div
          className="track"
          variants={marqueeVariants}
          animate="animate"
        >
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
          <h1>WAM IT IN THE ASS LIKE WABBIT</h1>
        </motion.div>
      </div>




      <div ref={ref4} className={`section ${inView4 ? 'visible' : 'hidden'} land2`}>

        <div className='leftyrighty leftyrighty2'>

          <div className='lefty '>
            <div className='h1'>This is the Biggest <span className='sText light'>Dick </span> on Solana.</div>


            <div className='radca' onClick={copyText}>
              <p className='ca' id='ca'>{socials[4]}</p>

            </div>
          </div>

          <LazyLoadImage src={early} alt="hello" className='righty' />

        </div>



      </div >

      <div ref={ref6} className={`section ${inView6 ? 'visible' : 'hidden'} land2`}>

        <div className='leftyrighty leftyrighty3'>

          <LazyLoadImage src={mad} alt="hello" className='righty' />
          <div className='lefty'>
            <div className='h1'>There's only one <span className='sText light'>CHOICE</span>. Buy <span className='sText light'>$WABBIT</span>.</div>



            <LazyLoadImage src={swing} alt="hello" className='adventure' />
            {/* <video autoPlay loop muted playsInline className="adventure">
              <source src={swing} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
          </div>



        </div>



      </div >

      <div ref={ref3} className={`section ${inView3 ? 'visible' : 'hidden'} land extramargin`}>

        <div className='cardcontainer'>

          {
            memes.map((x, i) => {
              return <div className='card' key={i}>

                <LazyLoadImage src={x.meme} alt="hello" className='cardimage' />
                <div className='cardcontent'>
                  <div>{x.text}</div>
                </div>

              </div>
            })
          }

        </div>


      </div>




      <div ref={ref8} className={`section ${inView8 ? 'visible' : 'hidden'} land extramargin`}>

        <div className='centercolumn'>
          <h1>
            BASES OF <span className='sText light'>WABBIT </span>
          </h1>
          <div className='rowrow rowrownorm'>

            <div className='rowleft'>
              <h1 className='rmaph'>1ST BASE</h1>
              <p className='rmapp'>GET TO KNOW WABBIT</p>
            </div>

            <LazyLoadImage src={r1} alt='hello' className='rowRight' />
          </div>
          <div className='rowrow rowrowre'>

            <LazyLoadImage src={r2} alt='hello' className='rowRight' />

            <div className='rowleft'>
              <h1 className='rmaph'>2ND BASE</h1>
              <p className='rmapp'>Grab Wabbit's Dick</p>
            </div>

          </div>
          <div className='rowrow rowrownorm'>

            <div className='rowleft'>
              <h1 className='rmaph'>3RD BASE </h1>
              <p className='rmapp'>LET WABBIT WAM IT</p>
            </div>

            <LazyLoadImage src={r3} alt='hello' className='rowRight' />
          </div>





        </div>

      </div>




      <div ref={ref5} className={`section ${inView5 ? 'visible' : 'hidden'} land extramargin`}>

        <div className='centercolumn'>
          <div className='ltextcont2'>

            <h1>HOW TO BUY</h1>

          </div>
          <div className='landscape'>


            <LazyLoadImage src={red} alt="hello" className='stickerLeft' />
            {/* <video autoPlay loop muted playsInline className="stickerLeft">
              <source src={red} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}

            {/* <LazyLoadImage src={sticker} alt='hello' className='stickerLeft' /> */}
            <div className='centercolumnimg'>
              <h1>Step One</h1>

              <LazyLoadImage src={wlt} alt="hello" />
            </div>
            <div className='colcol'>
              <h1>Create a Wallet</h1>
              <p>Download the Phantom wallet or your preferred wallet from the App Store or Google Play Store at no cost. If you're using a desktop, obtain the Google Chrome extension.</p>
            </div>
          </div>
          <div className='landscape'>

            <LazyLoadImage src={sticker} alt='hello' className='stickerRight' />
            <div className='centercolumnimg'>
              <h1>Step Two</h1>
              <LazyLoadImage src={wt} alt="hello" />
            </div>
            <div className='colcol'>
              <h1>Get Sol</h1>
              <p>Ensure you have Sol in your wallet to convert for $WABBIT. If you don't possess any Sol, you can acquire it directly on your wallet or through an exchange.</p>
            </div>
          </div>
          <div className='landscape'>

            {/* 
            <video autoPlay loop muted playsInline className="stickerLeft">
              <source src={elec} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}


            <LazyLoadImage src={elec} alt="hello" className='stickerLeft' />
            <div className='centercolumnimg'>
              <h1>Step Three</h1>
              <LazyLoadImage src={lgt} alt="hello" />
            </div>
            <div className='colcol'>
              <h1>Go to Jup.ag</h1>
              <p>Connect your wallet to the dex and then buy $WABBIT</p>
            </div>
          </div>



        </div>

      </div>







      {/* <div className='marquee-contmemes'>

        <motion.div
          className="track"
          variants={marqueeVariants}
          animate="animate"
        >
          {
            memes.map((x, i) => {
              return <LazyLoadImage src={x} key={i} alt='hello' className='marqimg' />
            })
          }


        </motion.div>
      </div> */}

      <div className='centercolumn extramargin'>


        <h1>WAM IT LIKE WABBIT</h1>

        <div className='buttonsH'>
          <a className='logs' href={socials[0]} target='_blank' rel="noreferrer">
            <LazyLoadImage src={tg} className='dex' alt='dex' />
          </a>

          <a className='logs' href={socials[1]} target='_blank' rel="noreferrer">
            <LazyLoadImage src={xcom} className='dex' alt='dex' />

          </a>
          <a className='logs' href={socials[2]} target='_blank' rel="noreferrer">
            <LazyLoadImage src={dex} className='dex' alt='dex' />
          </a>
          <div className='btnBig'><a href={socials[3]} target="_blank" rel="noreferrer">TRADE</a></div>
        </div>



      </div>

      <div className="foot">

        <LazyLoadImage src={footimg} alt='hello' className='hbg' />




      </div>

    </div >

  );
}

export default Home; 