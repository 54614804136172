import m1 from './1.PNG';
import m2 from './2.PNG';
import m3 from './3.PNG';
import m4 from './4.PNG';
import m5 from './5.PNG';
import m6 from './6.PNG';

const memes = [
    {
        meme: m1,
        text: "White rabbit gives a bj"
    },
    {
        meme: m2,
        text: "Older sister plays with Wabbit"
    },
    {
        meme: m3,
        text: "Wabbit peeping at someone"
    },
    {
        meme: m4,
        text: "Surprise at the bathroom"
    },
    {
        meme: m5,
        text: "Homeless rabbit thanks Wabbit's kindness"
    },
    {
        meme: m6,
        text: "Wabbit shows his big D"
    }
];

export default memes;